// task name when task completed
export enum AllTaskCompletedName {
  requestor_draft = "Requestor Draft Completed",
  supplier_accepted = "Supplier Accepted Invitation",
  profile_draft = "Supplier Draft Completed",
  BI = "BI/BDA Risk Assessment Completed",
  GDPR = "Data Protection Risk Assessment Completed",
  SST_review = "AP SST Review",
  QC_review = "AP QC Review",
  SST_rejectToBuyer = "Requestor Form Resubmitted",
  SST_rejectToSupplier = "Supplier Form Resubmitted",
  profile_resubmit= "Supplier Form Resubmitted",
  poc_email_updated = "POC Email Updated",
  Deactivated = "Deactivated"
}

// all task name
export enum AllTaskKey {
  requestor_draft = "Requestor Draft Input",
  supplier_accepted = "Await Supplier Accept Invitation",
  profile_draft = "Supplier Draft Input",
  BI = "BI/BDA Risk Assessment",
  GDPR = "GDPR Risk Assessment",
  SST_review = "AP SST Review",
  QC_review = "AP QC Review",
  SST_rejectToBuyer = "Pending More Info From Requestor",
  SST_rejectToSupplier = "Pending More Info From Supplier",
  profile_resubmit= "Supplier Form Resubmitted",
  Onboarding_supplier_PocEmailUpdate = 'Onboarding Supplier POC Email Update',
  Deactivated = "Deactivated"
}

export enum StatusHistoriesTitle {
  requestor_draft = "The Requestor has completed Requestor Draft",
  supplier_accepted = "The supplier has accepted invitation",
  profile_draft = "The supplier has completed Supplier Draft",
  SST_review = "AP SST Review Approved",
  SST_rejectToBuyer = "AP SST has rejected to buyer",
  SST_rejectToSupplier = "AP SST has rejected to supplier",
  SST_terminate = "AP SST has terminated",
  QC_review = "AP QC Review Approved",
  QC_reject = "AP QC has rejected to AP SST",
  QC_terminate = "The AP QC has terminated",
  IS = "The IS Risk Assessment has been completed",
  GDPR = "The GDPR Risk Assessment has been completed",
  BI = "The BI/BDA Risk Assessment has been completed",
  SAP = "The AP QC has approved AP SST Review",
  onboarded = "The onboarding process has been completed",
  terminated = "The supplier has been terminated",
  supplier_form = "Change Supplier - Supplier Form",
  buyer_form = "Change Request - Requestor Form",
  profile_resubmit= "Supplier Form Resubmitted",
  poc_update = "The POC email has been updated",
  Deactivated = "Deactivated"
}

// all process name
export enum ProcessNames {
  generic_supplierSetup = "Generic Supplier Setup",
  generic_supplierMigrateSetup = "Generic Supplier Migrate Setup",
  generic_buyerChangeRequest = "Generic Buyer Change Request",
  generic_supplierChangeRequest = "Generic Supplier Change Request",
  generic_supplierSetupSubProcess = "Generic Supplier Setup Sub Process",
  generic_start_pocEmailUpdate = 'Start POC Email Update',
  Deactivated = "Deactivated"
}

// all process name mapping title
export enum ProcessNamesTitle {
  generic_supplierSetup = "New Supplier Set Up",
  generic_supplierMigrateSetup = "Migrated Supplier Set Up",
  generic_buyerChangeRequest = "Requestor Change Request Review",
  generic_supplierChangeRequest = "Supplier Change Request Review",
  generic_start_pocEmailUpdate = 'Start POC Email Update',
  Deactivated = "Deactivated"
}

// supplier setup process's tasks
export enum SetupProcess {
  requestor_draft = "Requestor Draft Input",
  supplier_accepted = "Await Supplier Accept Invitation",
  profile_draft = "Supplier Draft Input",
  BI = "BI/BDA Risk Assessment",
  GDPR = "GDPR Risk Assessment",
  SST_review = "AP SST Review",
  QC_review = "AP QC Review",
  SST_rejectToBuyer = "Pending More Info From Requestor",
  SST_rejectToSupplier = "Pending More Info From Supplier",
  profile_resubmit= "Supplier Form Resubmitted",
  Onboarding_supplier_PocEmailUpdate = 'Onboarding Supplier POC Email Update',
  Deactivated = "Deactivated"
}

// supplier migrate setup process's tasks
export enum MigrateProcess {
  supplier_accepted = "Await Supplier Accept Invitation",
  profile_draft = "Supplier Draft Input",
  SST_review = "AP SST Review",
  QC_review = "AP QC Review",
  SST_rejectToSupplier = "Pending More Info From Supplier",
  profile_resubmit= "Supplier Form Resubmitted",
  Onboarding_supplier_PocEmailUpdate = 'Onboarding Supplier POC Email Update',
  Deactivated = "Deactivated"
}

// buyer change request process's tasks
export enum BuyerChangeRequestProcess {
  SST_review = "AP SST Review",
  QC_review = "AP QC Review",
  GDPR = "GDPR Risk Assessment",
  Deactivated = "Deactivated"
}

// supplier change request process's tasks
export enum SupplierChangeRequestProcess {
  SST_review = "AP SST Review",
  SST_rejectToSupplier = "Pending More Info From Supplier",
  QC_review = "AP QC Review",
  Deactivated = "Deactivated"
}

export enum ProcessStatus {
  pending = "pending",
  completed = "completed",
}
export class HistoryTask {
  id?: number;
  // task name
  name: string;
  assignee: string;
  candidateGroup: string;
  candidateUser: string;
  // process Definition GUID
  processDefId: string;
  // process Instance GUID
  processInstId: string;
  // task Definition GUID
  taskDefId: string;
  // task Instance GUID
  taskInstId: string;
  // business key. E.g: supplierCode
  businessKey: string;
  executionId: number;
  startUser: string;
  startTime: string;
  endTime: string;
  status: string;
  description: string;
  variablesStr: string;
  variablesObj: any;
  // process name
  processDefName: string;
}

export class Task {
  id?: number;
  // task name
  name?: string;
  assignee?: string;
  candidateGroup?: string;
  candidateUser?: string;
  // task Instance GUID
  taskInstId?: string;
  // task Definition GUID
  taskDefId?: string;
  // process Definition GUID
  processDefId?: string;
  // process Instance GUID
  processInstId?: string;
  // business key. E.g: supplierCode
  businessKey?: string;
  executionId?: number;
  startTime?: string;
  startUser?: string;
  description?: string;
  // process name
  processDefName?: string;
  variables?: any;
}

export class HistoryProcessInstance {
  id?: number;
  // process name
  name?: string;
  type?: string;
  // master process Instance GUID
  parentProcessInstId?: string;
  // process Instance GUID
  processInstId?: string;
  // process Definition GUID
  processDefId?: string;
  // business key. E.g: supplierCode
  businessKey?: string;
  startUser?: string;
  startTime?: string;
  endTime?: string;
  startNodeId?: string;
  endNodeId?: string;
  endType?: string;
  status?: string;
  description?: string;
}

export type CurrentProcess = ProcessInstance | HistoryProcessInstance;
export class ProcessInstance {
  id?: number;
  // process name
  name: string;
  // business key. E.g: supplierCode
  businessKey: string;
  // parentProcessInstId = 0 is parent process
  type: string;
  // master process Instance GUID
  parentProcessInstId: string;
  // process Instance GUID
  processInstId: string;
  // process Definition GUID
  processDefId: string;
  startUser: string;
  startTime: string;
  endTime: string;
  startNodeId: string;
  description: string;
}

export enum APCompleteType {
  approve = "approve",
  reject = "reject",
  terminate = "terminate",
}

export enum WorkflowEndType {
  noneEnd = "none_end_event",
  terminateEnd = "terminate_end_event",
}

export function getTaskCompletedName(taskName: string): string {
  if (taskName === AllTaskKey.BI) {
    return AllTaskCompletedName.BI;
  } else if (taskName === AllTaskKey.GDPR) {
    return "Information Security & Data Protection Completed";
  } else if (taskName === AllTaskKey.QC_review) {
    return AllTaskCompletedName.QC_review;
  } else if (taskName === AllTaskKey.SST_rejectToBuyer) {
    return AllTaskCompletedName.SST_rejectToBuyer;
  } else if (taskName === AllTaskKey.SST_rejectToSupplier) {
    return AllTaskCompletedName.SST_rejectToSupplier;
  } else if (taskName === AllTaskKey.SST_review) {
    return AllTaskCompletedName.SST_review;
  } else if (taskName === AllTaskKey.profile_draft) {
    return AllTaskCompletedName.profile_draft;
  } else if (taskName === AllTaskKey.requestor_draft) {
    return AllTaskCompletedName.requestor_draft;
  } else if (taskName === AllTaskKey.supplier_accepted) {
    return AllTaskCompletedName.supplier_accepted;
  } 
  else if (taskName === AllTaskKey.profile_resubmit) {
      return AllTaskCompletedName.profile_resubmit;
  }else if (taskName === AllTaskKey.Onboarding_supplier_PocEmailUpdate) {
    return AllTaskCompletedName.poc_email_updated;
  } else if(AllTaskKey.Deactivated){
    return AllTaskCompletedName.Deactivated;
  }  else {
    return "";
  }
}

export function getProcessTitle(taskName: string): string {
  if (taskName === ProcessNames.generic_supplierSetup) {
    return ProcessNamesTitle.generic_supplierSetup;
  } else if (taskName === ProcessNames.generic_supplierMigrateSetup) {
    return ProcessNamesTitle.generic_supplierMigrateSetup;
  } else if (taskName === ProcessNames.generic_buyerChangeRequest) {
    return ProcessNamesTitle.generic_buyerChangeRequest;
  } else if (taskName === ProcessNames.generic_supplierChangeRequest) {
    return ProcessNamesTitle.generic_supplierChangeRequest;
  } else if (taskName === ProcessNames.generic_start_pocEmailUpdate) {
    return ProcessNamesTitle.generic_start_pocEmailUpdate;
  } else if (taskName === ProcessNames.Deactivated) {
    return ProcessNamesTitle.Deactivated;
  }else {
    return "";
  }
}
