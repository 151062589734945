export const environment = {
  providers: "app",
  production: true,
  role: "buyer",
  gateway: "https://supplierhub-app.ciotest.accenture.com/api",
  env: "test",
  languageSwitch: false,
  notification_switch: false,
  landing_url: "https://tsm.ciotest.accenture.com/#/",
  relationship_url: "https://tsm.ciotest.accenture.com/#/supplier-profile/",
  landingdev_url: "https://tsm.ciodev.accenture.com/#/",
  relationshipdev_url: "https://tsm.ciodev.accenture.com/#/supplier-profile/",
  landingtest_url: "https://tsm.ciotest.accenture.com/#/",
  relationshiptest_url: "https://tsm.ciotest.accenture.com/#/supplier-profile/",
  landingperf_url: "https://tsm-perf.ciodev.accenture.com/#/",
  relationshipperf_url: "https://tsm-perf.ciodev.accenture.com/#/supplier-profile/",
  landinghotfix_url: "https://tsm.ciohotfix.accenture.com/#/",
  relationshiphotfix_url: "https://tsm.ciohotfix.accenture.com/#/supplier-profile/",
  landingstage_url: "https://tsm.ciostage.accenture.com/#/",
  relationshipstage_url:
    "https://tsm.ciostage.accenture.com/#/supplier-profile/",
    buyerGateway:"https://supplierhub-app.ciotest.accenture.com/buyer",
    supplierGateway:"https://supplierhub-app.ciotest.accenture.com/supplier",
    stakeholderGateway:"https://supplierhub-app.ciotest.accenture.com/stakeholder",
    ldClientId:"665032916a810810711446fb"
};
