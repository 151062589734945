import { Injectable } from "@angular/core";
import { AuthService } from "src/app/services/auth/auth.service";
import { CustomFilter } from "src/app/dynamic-components/interfaces/Decorators";
import { DynamicContext } from "src/app/dynamic-components/interfaces/DynamicContext";
import { ComponentConfig } from "src/app/dynamic-components/ComponentConfig";
import { UntypedFormGroup } from "@angular/forms";
import { DynamicComponentService } from "src/app/dynamic-components/services/dynamic-component.service";
import { OnBoardingService } from "../../modules/buyer/services/onboarding.service";
import { SpendCategoryGroup } from "src/app/interfaces/mapping";
import { supplierGeographical } from "src/app/shared/shared";
import { environment } from "src/environments/environment";

@Injectable()
export class CustomFilters {
  constructor(
    private authService: AuthService,
    private dynamicService: DynamicComponentService,
    private onBoardingService: OnBoardingService
  ) {}

  @CustomFilter("acl")
  aclCheck(
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): boolean => {
      if (environment.role === "supplier") {
        return true;
      }
      if (!this.authService.passport.buyer) {
        return true;
      }
      let buyerRoleCode = this.authService.passport.buyer.RoleCode;
      buyerRoleCode = buyerRoleCode === 'systemAdmin' ? 'buyerAdmin' : buyerRoleCode
      const buyerEmail = this.authService.passport.buyer.Email;
      const creadteBy =
        this.onBoardingService.supplierModel.Mapping.requestor["createdBy"];
      if (buyerEmail === creadteBy) {
        return true;
      } else if (buyerRoleCode == "requestor") {
        const hasRole = params
          .split(",")
          .find((p: string) => p == "otherRequestor");
        return !!hasRole;
      } else {
        const arr = params.split(",");
        if ((config.name == 'UsaEmpIdNum' || config.name == 'expressionACL') && this.authService.passport.buyer.RoleCode == 'systemAdmin') {
          arr.push('buyerAdmin');
        }
        const hasRole = arr.find((p: string) => p.trim() == buyerRoleCode);
        return !!hasRole;
      }
    };
  }

  @CustomFilter("aclSystem")
  aclSystemCheck(
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): boolean => {
      if (config.name == "SocialObligationsAttachment") {
        console.log("entered");
      }
      if (environment.role === "supplier") {
        return true;
      }
      if (!this.authService.passport.buyer) {
        return true;
      }
      let buyerRoleCode = this.authService.passport.buyer.RoleCode;
      const buyerEmail = this.authService.passport.buyer.Email;
      const creadteBy =
        this.onBoardingService.supplierModel.Mapping.requestor["createdBy"];
      if (buyerEmail === creadteBy) {
        return true;
      } else if (buyerRoleCode == "requestor") {
        const hasRole = params
          .split(",")
          .find((p: string) => p == "otherRequestor");
        return !!hasRole;
      } else {
        const arr = params.split(",");
        const hasRole = arr.find((p: string) => p.trim() == buyerRoleCode);
        return !!hasRole;
      }
    };
  }

  @CustomFilter("SpendCategoryGroup")
  SpendCategoryGroupFilter(
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): boolean => {
      if (context.getValue("parentModel").RequestForm.RequestorDetailsInfo.IndependentContractor == "Y") {
        SpendCategoryGroup['5002'] = false
        SpendCategoryGroup['5021'] = false
      } else {
        SpendCategoryGroup['5002'] = true
        SpendCategoryGroup['5021'] = true
      }
      return SpendCategoryGroup[
        this.onBoardingService.supplierModel.RequestForm.RequestorDetailsInfo
          .SpendCategoryGroup
      ];
    };
  }
  @CustomFilter("isShowUploadFinancialCopy")
  iShowUploadFinancialCopyFilter(
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): boolean => {
      if (
        this.onBoardingService.supplierModel.RequestForm.RequestorDetailsInfo
          .SupplierGeographical === supplierGeographical.MET
      ) {
        return true;
      }
      return this.onBoardingService.isBIApplicable;
    };
  }

  @CustomFilter("isNotSupplierCountryUSAOrPR")
  isNotSupplierCountryUSAOrPR(
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): boolean => {
      const countries: Array<string> =
        context.get("parentModel").value.RequestForm.RequestorDetailsInfo
          .SupplierCountry;
      if (!countries.includes("US") && !countries.includes("PR")) {
        return true;
      }
      return false;
    };
  }

  @CustomFilter("isSupplierCountryUSAOrPR")
  isSupplierCountryUSAOrPR(
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): boolean => {
      const countries: Array<string> =
        context.get("parentModel").value.RequestForm.RequestorDetailsInfo
          .SupplierCountry;
      if (countries.includes("US") || countries.includes("PR")) {
        return true;
      }
      return false;
    };
  }

  @CustomFilter("isSupplierCountryUSAOrCA")
  isSupplierCountryUSAOrCA(
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): boolean => {
      if (!(context.getValue("isCanada") || context.getValue("isUnitedStates"))) {
        return false;
      } else {
        const countries: Array<string> =
          context.get("parentModel").value.RequestForm.RequestorDetailsInfo
            .SupplierCountry;
        if (
          (countries.includes("PR") &&
            countries.includes("Ca") &&
            !countries.includes("US")) ||
          (countries.includes("PR") &&
            !countries.includes("US") &&
            !countries.includes("Ca"))
        ) {
          return false;
        }
        return true;
      }
    };
  }
}
