import * as _ from "lodash";
import { Injectable } from "@angular/core";

@Injectable()
export class DictionaryService {
  public getGeographicUnits(dictionary: any[]) {
    const geographicUnits = _.chain(dictionary)
      .filter({ Code: "requestor-details" })
      .map("Items")
      .compact()
      .flatMap()
      .filter(
        ({ Code }) =>
          Code === "SupplierGeographical" ||
          Code === "SupplierGeographicalUKI" ||
          Code === "SupplierGeographicalANZ"
      )
      .map("Items")
      .compact()
      .flatMap()
      .value();
    return geographicUnits;
  }

  public getCountries(dictionary: any[]) {
    const geographicUnits = this.getGeographicUnits(dictionary);
    const countries = _.chain(geographicUnits)
      .map("Items")
      .compact()
      .flatMap()
      .value();
    return countries;
  }

  public getCompanyCountryMappings(dictionary: any[]) {
    const companyCountryMappings = _.chain(dictionary)
      .filter({ Code: "requestor-details" })
      .map("Items")
      .compact()
      .flatMap()
      .filter({ Code: "CompanyCode" })
      .map("Items")
      .compact()
      .flatMap()
      .value();
    return companyCountryMappings;
  }

  public getPaymentTerms(dictionary: any[]) {
    const paymentTerms = _.chain(dictionary)
      .filter({ Code: "requestor-form" })
      .map("Items")
      .compact()
      .flatMap()
      .filter(
        ({ Code }) =>
          Code === "SpendCommodityGroup" ||
          Code === "SpendCommodityGroupNA" ||
          Code === "SpendCommodityGroupCA" ||
          Code === "SpendCommodityGroupAsen" ||
          Code === "SpendCommodityGroupANZ" ||
          Code === "SpendCommodityGroupGreaterChina" ||
          Code === "SpendCommodityGroupTRorRS"
      )
      .map("Items")
      .compact()
      .flatMap()
      .uniqBy("Code")
      .value();
    return paymentTerms;
  }
}
